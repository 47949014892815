














































import { Component, Vue } from "vue-property-decorator";
import { UtilsString } from "@/utils/utils-string";
import { paciente } from "@/shared/dtos/paciente-dto";
import pacienteModule from "@/store/modules/paciente-module";
import { UtilsEncript } from "@/utils/utils-encript";
@Component({
  $_veeValidate: { validator: "new" },
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    PacienteFichaOpciones: () =>
      import("@/views/pacientes/paciente-ficha-opciones.vue"),
    InformacionBasicaPaciente: () =>
      import(
        "@/views/pacientes/información_paciente/informacion_basica_paciente.vue"
      ),
  },
})
export default class paciente_notificaciones_push extends Vue {
  public id_paciente: number = 0;
  public recordatorio_cita: boolean = true;
  created() {
    if (UtilsString.IsValidParamUrl(this.$route.query.id_paciente)) {
      this.id_paciente = Number.parseInt(
        UtilsEncript.DesEncriptar(this.$route.query.id_paciente)
      );
      pacienteModule.getpaciente(
        new paciente({
          numero: this.id_paciente,
        })
      );
    }
  }
  resetear() {
    pacienteModule.getpaciente(
      new paciente({
        numero: this.id_paciente,
      })
    );
  }
  aceptar() {
    pacienteModule.actualizarpaciente(this.paciente).then(() => {});
  }

  public get tiene_email() {
    return !UtilsString.IsNullOrWhiteSpace(
      UtilsString.ValueOf(this.paciente.email)
    );
  }

  public get paciente() {
    return pacienteModule.paciente;
  }
}
